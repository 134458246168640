import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { login } from "../../actions/collector/auth";
import {
  inquiry as inquiryByCollector,
  purchase as purchaseByCollector,
} from "../../actions/collector/purchase";
import { inquiry, purchase } from "../../actions/purchase";
import {
  SALE_AVAILABLE,
  SALE_CONTACT_US,
  SALE_FOR_SALE,
  SALE_NOT_FOR_SALE,
  SALE_SOLD,
} from "../../constants/option";
import { Path, PathGenerator } from "../../constants/path";
import { Formatter } from "../../utils/formatter";
import { defaultLang, Location } from "../../utils/location";
import Logger from "../../utils/logger";
import CollectorInquiryForm from "../forms/collector/InquiryForm";
import CollectorPurchaseForm from "../forms/collector/PurchaseForm";
import InquiryForm from "../forms/InquiryForm";
import LoginForm from "../forms/LoginForm";
import PurchaseForm from "../forms/PurchaseForm";
import { Link } from "../util/Clickable";
import { IntlHTML, IntlText } from "../util/Text";

// Use the ESM compatible approach for TypeScript
const UIkit = window.UIkit;

class Purchase extends Component {
  state = {
    visible: {
      inquiry: false,
      purchase: false,
    },
  };

  redirectUrl = (mode) => {
    return (
      process.env.REACT_APP_URL +
      PathGenerator.getLocalPath(Path.artworks.view[mode].url, {
        artworkId: this.props.artwork.id,
      })
    );
  };

  onSubmitLogin = (values) => {
    return this.props.actions.login(values);
  };

  onSuccessLogin = (data) => {
    Logger.event({
      category: "register",
      action: "register",
      label: window.location.href,
    });
    const lang = data.primary_lang ? data.primary_lang : defaultLang;
    this.props.actions.setViewLang(lang);
    this.props.actions.setEditLang(lang);
  };

  onInquiry = (values) => {
    Logger.inquiry(
      this.props.artwork.sale.price,
      this.props.artwork.sale.price_unit,
      this.props.artwork.id,
    );
    if (this.props.collector.id != null) {
      return this.props.actions.inquiryByCollector(
        this.props.artwork.id,
        values,
      );
    } else {
      return this.props.actions.inquiry(this.props.artwork.id, values);
    }
  };

  onSuccessContact = (data) => {
    this.props.history.push(
      PathGenerator.getLocalPath(
        Path.artworks.view.inquiried.url,
        { artworkId: this.props.artwork.id },
        { gallery_id: this.props.artwork.sale.gallery_id },
      ),
    );
  };

  onPurchase = (values) => {
    Logger.purchase(
      this.props.artwork.sale.price,
      this.props.artwork.sale.price_unit,
      this.props.artwork.id,
    );
    if (this.props.collector.id != null) {
      return this.props.actions.purchaseByCollector(
        this.props.artwork.id,
        values,
      );
    } else {
      return this.props.actions.purchase(this.props.artwork.id, values);
    }
  };

  onSuccessPurchase = (values) => {
    this.props.history.push(
      PathGenerator.getLocalPath(
        Path.artworks.view.thanks.url,
        { artworkId: this.props.artwork.id },
        { gallery_id: this.props.artwork.sale.gallery_id },
      ),
    );
  };

  getInitialValues = () => {
    return Object.assign({}, this.props.collector, {
      collector_message: "",
      gallery_id: this.props.artwork.sale.gallery_id,
    });
  };

  onToggleInquiry = () => {
    this.setState({
      visible: {
        inquiry: !this.state.visible.inquiry,
        purchase: this.state.visible.purchase,
      },
    });
  };

  onTogglePurchase = () => {
    this.setState({
      visible: {
        inquiry: this.state.visible.inquiry,
        purchase: !this.state.visible.purchase,
      },
    });
  };

  renderInquiryForm = (form, onSubmit) => {
    if (this.props.collector.id != null) {
      return (
        <CollectorInquiryForm
          form={"collector-" + form}
          initialValues={this.getInitialValues()}
          onSubmit={this.onInquiry}
          onSuccess={onSubmit}
        />
      );
    } else {
      return (
        <div>
          <div className="login-form">
            <IntlText id="views.purchase.ifyouhaveaccount" />
            <Link
              data-uk-toggle="target: .login-form"
              ga={{
                category: "button",
                action: "purchase",
                label: "inquiry_login",
              }}
            >
              <IntlText id="views.purchase.clickme" />
            </Link>
          </div>
          <div className="login-form" hidden>
            <IntlText id="views.purchase.ifyoudonthaveaccount" />
            <Link
              data-uk-toggle="target: .login-form"
              ga={{
                category: "button",
                action: "purchase",
                label: "inquiry_login",
              }}
            >
              <IntlText id="views.purchase.notlogin" />
            </Link>
          </div>
          <div className="uk-margin-small login-form" hidden>
            <div className="uk-margin-ssmall">
              <LoginForm
                onSubmit={this.onSubmitLogin}
                onSuccess={this.onSuccessLogin}
              />
            </div>
            <hr />
            <div className="uk-margin-ssmall uk-text-center">
              <IntlHTML id="util.like_pops.lead" />
            </div>
            <div className="uk-margin-ssmall uk-text-center">
              <Link
                className="uk-button uk-button-default"
                to={Path.collector.auth.register.url}
                ga={{
                  category: "button",
                  action: "purchase",
                  label: "inquiry_register",
                }}
              >
                <IntlText id="collector.auth.login.to_register" />
              </Link>
            </div>
          </div>
          <div className="uk-margin-small login-form">
            <InquiryForm
              form={form}
              initialValues={this.getInitialValues()}
              onSubmit={this.onInquiry}
              onSuccess={onSubmit}
            />
          </div>
        </div>
      );
    }
  };

  renderPurchaseForm = () => {
    if (this.props.collector.id != null) {
      return (
        <CollectorPurchaseForm
          price={this.props.artwork.sale.price}
          priceUnit={this.props.artwork.sale.price_unit}
          initialValues={this.getInitialValues()}
          redirectUrl={this.redirectUrl}
          onSubmit={this.onPurchase}
          onSuccess={(values) => {
            this.onSuccessPurchase(values);
          }}
        />
      );
    } else {
      return (
        <div>
          <div className="login-form">
            <IntlText id="views.purchase.ifyouhaveaccount" />
            <Link
              data-uk-toggle="target: .login-form"
              ga={{
                category: "button",
                action: "purchase",
                label: "inquiry_login",
              }}
            >
              <IntlText id="views.purchase.clickme" />
            </Link>
          </div>
          <div className="login-form" hidden>
            <IntlText id="views.purchase.ifyoudonthaveaccount" />
            <Link
              data-uk-toggle="target: .login-form"
              ga={{
                category: "button",
                action: "purchase",
                label: "inquiry_login",
              }}
            >
              <IntlText id="views.purchase.notlogin" />
            </Link>
          </div>
          <div className="uk-margin-small login-form" hidden>
            <div className="uk-margin-ssmall">
              <LoginForm
                onSubmit={this.onSubmitLogin}
                onSuccess={this.onSuccessLogin}
              />
            </div>
            <hr />
            <div className="uk-margin-ssmall uk-text-center">
              <IntlHTML id="util.like_pops.lead" />
            </div>
            <div className="uk-margin-ssmall uk-text-center">
              <Link
                className="uk-button uk-button-default"
                to={Path.collector.auth.register.url}
                ga={{
                  category: "button",
                  action: "purchase",
                  label: "inquiry_register",
                }}
              >
                <IntlText id="collector.auth.login.to_register" />
              </Link>
            </div>
          </div>
          <div className="uk-margin-small login-form">
            <PurchaseForm
              price={this.props.artwork.sale.price}
              priceUnit={this.props.artwork.sale.price_unit}
              initialValues={this.getInitialValues()}
              redirectUrl={this.redirectUrl}
              onSubmit={this.onPurchase}
              onSuccess={(values) => {
                this.onSuccessPurchase(values);
              }}
            />
          </div>
        </div>
      );
    }
  };

  renderPurchase = (gallery) => {
    return (
      <div>
        <div className="uk-margin uk-text-right">
          {
            this.props.artwork.sale.price_unit === "$" &&
            <span className="uk-h3 uk-text-muted uk-text-mincho uk-text-uppercase">
              us
              {this.props.artwork.sale.price_unit}
            </span>
          }
          {
            this.props.artwork.sale.price_unit === "jpy" &&
            <span className="uk-h3 uk-text-muted uk-text-mincho uk-text-uppercase">
              ¥
            </span>
          }
          <span className="uk-h1 uk-text-muted uk-margin-ssmall-right ">
            {Formatter.pricing(this.props.artwork.sale.price)}
          </span>
          <span className="uk-text-muted uk-text-mincho">
            <IntlText id="views.purchase.tax" />
          </span>
        </div>
        <div className="uk-margin" id="purchase_base">
          {this.state.visible.purchase ? (
            <div>{this.renderPurchaseForm()}</div>
          ) : (
            <Link
              ga={{
                category: "modal",
                action: "purchase",
                label: "purchase_artwork_" + this.props.artwork.id,
              }}
              ref={(purchaseButton) => (this.purchaseButton = purchaseButton)}
              className="uk-button uk-button-accent uk-button-large uk-width-1-1"
              onClick={this.onTogglePurchase}
            >
              <IntlText id="views.purchase.title" />
            </Link>
          )}
        </div>
        <hr />
        <div className="uk-margin" id="inquiry_base">
          <p className="uk-margin-ssmall">
            <IntlText id="views.purchase.lead_contact" />
          </p>
          {this.state.visible.inquiry ? (
            <div className="uk-margin-ssmall">
              {this.renderInquiryForm("inquiry-form", this.onSuccessContact)}
            </div>
          ) : (
            <div className="uk-margin-ssmall uk-text-center">
              <Link
                onClick={this.onToggleInquiry}
                className="uk-button uk-button-default uk-width-1-1 uk-padding-remove"
                ga={{
                  category: "modal",
                  action: "purchase",
                  label: "contact_artwork_" + this.props.artwork.id,
                }}
              >
                <IntlText id="views.purchase.contact" />
              </Link>
            </div>
          )}
        </div>
      </div>
    );
  };

  renderInquiry = (gallery) => {
    return (
      <div>
        {this.props.artwork.sale.price != null &&
          this.props.artwork.sale.price != 0 && (
            <div className="uk-margin uk-text-right">
              {
                this.props.artwork.sale.price_unit === "$" &&
                <span className="uk-h3 uk-text-muted uk-text-mincho uk-text-uppercase">
                  us
                  {this.props.artwork.sale.price_unit}
                </span>
              }
              {
                this.props.artwork.sale.price_unit === "jpy" &&
                <span className="uk-h3 uk-text-muted uk-text-mincho uk-text-uppercase">
                  ¥
                </span>
              }
              <span className="uk-h1 uk-text-muted uk-margin-ssmall-right">
                {Formatter.pricing(this.props.artwork.sale.price)}
              </span>
              <span className="uk-text-muted uk-text-mincho">
                <IntlText id="views.purchase.tax" />
              </span>
            </div>
          )}
        <div className="uk-margin">
          <p className="uk-margin-small uk-text-muted">
            <IntlText id="views.purchase.lead_contact" />
          </p>
          {this.state.visible.inquiry ? (
            <div className="uk-margin-small">
              {this.renderInquiryForm("inquiry-form-sp", this.onSuccessContact)}
            </div>
          ) : (
            <div className="uk-margin-small">
              <button
                type="button"
                className="uk-button uk-button-accent uk-button-large uk-width-1-1 uk-padding-remove"
                onClick={this.onToggleInquiry}
              >
                <IntlText id="views.purchase.contact" />
              </button>
            </div>
          )}
        </div>
      </div>
    );
  };

  render = () => {
    const sale = this.props.artwork.sale;
    const gallery = Formatter.mergeDesc(
      sale.gallery,
      sale.gallery.gallery_descs,
      this.props.language.view,
    );

    const locations = gallery.locations.map((location) => {
      return Formatter.mergeDesc(
        location,
        location.location_descs,
        this.props.language.view,
      );
    });

    return (
      <div className="uk-margin-medium">
        <div className="uk-chip" />
        <div className="uk-margin">
          {(() => {
            if (
              sale.status == SALE_CONTACT_US ||
              sale.status == SALE_FOR_SALE
            ) {
              return this.renderInquiry(gallery);
            } else if (sale.status == SALE_AVAILABLE) {
              if (Location.getLang() == "ja") {
                return this.renderPurchase(gallery);
              } else {
                return this.renderInquiry(gallery);
              }
            } else if (sale.status == SALE_SOLD) {
              return (
                <div className="uk-text-left">
                  <span className="uk-text-muted uk-h3">
                    <IntlText id="option.sales_status.sold" />
                  </span>
                </div>
              );
            } else if (sale.status == SALE_NOT_FOR_SALE) {
              return (
                <div className="uk-text-left">
                  <span className="uk-text-muted uk-h4">
                    <IntlText id="option.sales_status.not_sale" />
                  </span>
                </div>
              );
            }
          })()}
        </div>
        <div className="uk-margin-large">
          <span className="uk-underline uk-h3">
            <Link
              className="uk-link-reset"
              to={PathGenerator.getLocalPath(Path.galleries.view.url, {
                galleryId: gallery.id,
              })}
              ga={{
                category: "link",
                action: "artwork",
                label: "gallery_" + gallery.id,
              }}
            >
              {gallery.name}
            </Link>
          </span>
          <div>
            <span
              data-uk-icon="icon:location; ratio:0.8;"
              className="uk-margin-ssmall-right"
            />
            <span>
              {(() => {
                if (sale.location) {
                  return sale.location.place;
                } else {
                  return locations.map((location) => location.place).join(", ");
                }
              })()}
            </span>
          </div>
        </div>
      </div>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    language: state.language,
    collector:
      state.auth.data && state.auth.role == "collector" ? state.auth.data : {},
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      login: (...param) => dispatch(login(...param)),
      inquiry: (...param) => dispatch(inquiry(...param)),
      purchase: (...param) => dispatch(purchase(...param)),
      inquiryByCollector: (...param) => dispatch(inquiryByCollector(...param)),
      purchaseByCollector: (...param) =>
        dispatch(purchaseByCollector(...param)),
    },
    dispatch,
  ),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Purchase),
);
