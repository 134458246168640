import React from "react";
import { createPurchaseForm } from "./commons/BasePurchaseForm";

/**
 * 一般向け購入フォーム
 * 共通フォームコンポーネントをベースにカスタマイズ
 */
const StandardPurchaseForm = createPurchaseForm("purchase-form", true);

// 一般向けにカスタマイズしたPurchaseFormのラッパーコンポーネント
const PurchaseForm = (props) => {
  return (
    <StandardPurchaseForm
      {...props}
      showNameField={true}
      readOnlyName={false}
      showEmailField={true}
      allowSavedCard={false}
    />
  );
};

export default PurchaseForm;
