import { useState } from "react";
import moment from "moment";
import { getPureMessage } from "../components/util/Text";

/**
 * Stripe支払い処理のためのカスタムフック
 * 新規カードと既存カードの両方の支払いフローと3Dセキュア認証を処理
 */
export const useStripePayment = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  /**
   * 支払い処理を実行
   * @param {object} options - 支払いオプション
   * @param {object} options.stripe - Stripeインスタンス
   * @param {object} options.elements - Stripe Elementsインスタンス
   * @param {object} options.values - フォームの値
   * @param {boolean} options.isNewCard - 新規カードかどうか
   * @param {Function} options.onSubmit - 支払いリクエスト送信関数
   * @param {Function} options.onSuccess - 支払い成功時のコールバック
   * @param {Function} options.mergeData - ユーザーデータをマージする関数
   */
  const processPayment = async ({
    stripe,
    elements,
    values,
    isNewCard,
    onSubmit,
    onSuccess,
    mergeData,
  }) => {
    setLoading(true);
    setError(null);

    try {
      mergeData && mergeData(values);

      // 既存のカードを使用する場合
      if (!isNewCard) {
        try {
          const response = await onSubmit(values);

          // サーバーからのエラーをチェック
          if (
            response &&
            response.value &&
            response.value.data &&
            response.value.data.error
          ) {
            console.error(
              "[DEBUG] Payment error from server:",
              response.value.data,
            );
            setError(getPureMessage("forms.attr.purchase.invalid_card"));
            setLoading(false);
            return null;
          }

          // リクエストデータと送信関数をレスポンスに保存
          if (response && response.value && response.value.data) {
            response.value.original_request = values;
            response.value.onSubmit = onSubmit;
          }

          return handleServerResponse({
            response,
            stripe,
            onSuccess,
          });
        } catch (err) {
          console.error("[DEBUG] Error submitting with existing card:", err);
          handleError(err);
          setLoading(false);
          return { success: false, error: err };
        }
      }

      // 新規カードの処理
      if (!stripe || !elements) {
        console.error(
          "[DEBUG] Stripe or elements not loaded - stripe:",
          !!stripe,
          "elements:",
          !!elements,
        );
        // 共通のエラーメッセージを使用
        setError(getPureMessage("global.error.stripe_not_loaded"));
        setLoading(false);
        return null;
      }

      console.log("[DEBUG] Starting new card processing flow");

      // Stripe Element の名前を修正
      const cardElement = elements.getElement("cardNumber");
      if (!cardElement) {
        console.error("[DEBUG] Card element not found in elements");
        // 共通のエラーメッセージを使用
        setError(getPureMessage("forms.attr.purchase.invalid_card"));
        setLoading(false);
        return null;
      }

      console.log("[DEBUG] Card element found, creating payment method");

      // 1. カード情報を取得
      let paymentMethodResult;
      try {
        const billingDetails = {
          name: values.name,
          email: values.email,
          phone: values.tel,
          address: {
            line1: values.address,
          },
        };
        console.log(
          "[DEBUG] Creating payment method with billing details:",
          JSON.stringify(billingDetails),
        );

        paymentMethodResult = await stripe.createPaymentMethod({
          type: "card",
          card: cardElement,
          billing_details: billingDetails,
        });

        console.log("[DEBUG] Payment method result:", paymentMethodResult);
      } catch (cardError) {
        console.error("[DEBUG] Error creating payment method:", cardError);
        // 全てのカード関連エラーを統一
        setError(getPureMessage("forms.attr.purchase.invalid_card"));
        setLoading(false);
        return null;
      }

      if (paymentMethodResult.error) {
        console.error(
          "[DEBUG] Payment method creation error:",
          paymentMethodResult.error,
        );
        // 全てのカード関連エラーを統一
        setError(getPureMessage("forms.attr.purchase.invalid_card"));
        setLoading(false);
        return null;
      }

      // 2. 支払い情報を準備
      const paymentMethod = paymentMethodResult.paymentMethod;
      console.log(
        "[DEBUG] Payment method created successfully:",
        paymentMethod.id,
      );
      console.log(
        "[DEBUG] Card details - last4:",
        paymentMethod.card.last4,
        "exp:",
        paymentMethod.card.exp_month + "/" + paymentMethod.card.exp_year,
        "brand:",
        paymentMethod.card.brand,
      );

      const param = {
        ...values,
        card_token: paymentMethod.id,
        card_suffix: paymentMethod.card.last4,
        expired_at: moment(
          `${paymentMethod.card.exp_year}-${paymentMethod.card.exp_month}-01`,
          "YYYY-MM-DD",
        )
          .endOf("month")
          .format("YYYY-MM-DD"),
      };

      console.log("[DEBUG] Prepared payment parameters:", {
        ...param,
        // 機密情報を除外
        card_token:
          "***" + (param.card_token ? param.card_token.slice(-4) : ""),
      });

      // 3. 支払いリクエスト送信
      try {
        console.log("[DEBUG] Submitting payment request to backend");
        const response = await onSubmit(param);
        console.log("[DEBUG] Backend response received:", response);

        // リクエストデータと送信関数をレスポンスに保存
        if (response && response.value && response.value.data) {
          response.value.original_request = param;
          response.value.onSubmit = onSubmit;
          console.log(
            "[DEBUG] Stored original request in response for potential 3D Secure flow",
          );
        }

        return handleServerResponse({
          response,
          stripe,
          onSuccess,
        });
      } catch (submitError) {
        console.error("[DEBUG] Error submitting payment request:", submitError);
        console.error(
          "[DEBUG] Error details:",
          JSON.stringify(submitError, Object.getOwnPropertyNames(submitError)),
        );
        // 全ての送信エラーを統一
        setError(getPureMessage("forms.attr.purchase.payment_failed"));
        setLoading(false);
        return { success: false, error: submitError };
      }
    } catch (error) {
      console.error("[DEBUG] Unexpected error in processPayment:", error);
      // 全ての予期しないエラーを統一
      setError(getPureMessage("forms.attr.purchase.payment_failed"));
      setLoading(false);
      return { success: false, error };
    } finally {
      // 確実にローディング状態を解除するためのfinallyブロック
      if (loading) {
        console.log("[DEBUG] Ensuring loading state is reset in finally block");
        setLoading(false);
      }
    }
  };

  /**
   * サーバーからの応答を処理
   */
  const handleServerResponse = async ({ response, stripe, onSuccess }) => {
    console.log("[DEBUG] handleServerResponse called with:", {
      responseStatus: response?.value?.status,
      hasErrors: response?.value?.errors
        ? Object.keys(response?.value?.errors).length > 0
        : false,
      hasData: !!response?.value?.data,
      stripeAvailable: !!stripe,
    });

    try {
      if (response.value.status >= 300) {
        console.error(
          "[DEBUG] Response has error status:",
          response.value.status,
        );
        console.error("[DEBUG] Response errors:", response.value.errors);

        const errors = Object.keys(response.value.errors).reduce((dic, key) => {
          dic[key] = Array.isArray(response.value.errors[key])
            ? response.value.errors[key].join("\n")
            : response.value.errors[key];
          return dic;
        }, {});
        throw errors;
      }

      const paymentData = response.value.data;
      console.log("[DEBUG] Payment data from server:", paymentData);

      // サーバーからのエラーレスポンスをチェック
      if (paymentData && paymentData.error) {
        console.error(
          "[DEBUG] Payment error detected in server response:",
          paymentData,
        );
        setError(getPureMessage("forms.attr.purchase.invalid_card"));
        setLoading(false);
        return null;
      }

      console.log(
        "----------------------------------------------------------------",
      );
      console.log("[DEBUG] paymentData:", paymentData);
      console.log(
        "----------------------------------------------------------------",
      );

      // 3Dセキュア認証が必要かチェック
      // Check if payment_intent_data exists and contains 3D Secure information
      const paymentIntentData = paymentData.payment_intent_data || {};
      console.log("[DEBUG] Payment intent data:", paymentIntentData);

      console.log("[DEBUG] Checking if 3D Secure is needed:", {
        payment_intent_data_exists: !!paymentData.payment_intent_data,
        requires_action: paymentIntentData.requires_action,
        client_secret: !!paymentIntentData.client_secret,
        status: paymentIntentData.status,
      });

      const needsConfirmation =
        paymentIntentData &&
        ((paymentIntentData.requires_action &&
          paymentIntentData.client_secret) ||
          (paymentIntentData.status === "requires_source_action" &&
            paymentIntentData.client_secret));

      console.log("[DEBUG] 3D Secure confirmation needed:", needsConfirmation);

      if (needsConfirmation) {
        console.log(
          "[DEBUG] Starting 3D Secure confirmation flow with client_secret:",
          paymentIntentData.client_secret
            ? "***" + paymentIntentData.client_secret.slice(-10)
            : null,
        );

        try {
          console.log("[DEBUG] Calling stripe.confirmCardPayment");
          const confirmResult = await stripe.confirmCardPayment(
            paymentIntentData.client_secret,
          );
          console.log("[DEBUG] confirmCardPayment raw result:", confirmResult);

          const { error, paymentIntent } = confirmResult;

          console.log("[DEBUG] confirmCardPayment result:", {
            error: error ? error.message : null,
            errorType: error ? error.type : null,
            paymentIntentStatus: paymentIntent ? paymentIntent.status : null,
            paymentIntentId: paymentIntent ? paymentIntent.id : null,
          });

          if (error) {
            console.error("[DEBUG] 3D Secure confirmation failed:", error);
            // Stripeから返される全ての3Dセキュア関連エラーを統一したメッセージで処理
            setError(getPureMessage("forms.attr.purchase.3d_secure_failed"));
            setLoading(false);
            return null;
          }

          if (
            paymentIntent.status === "succeeded" ||
            paymentIntent.status === "requires_capture"
          ) {
            console.log("[DEBUG] Payment successful:", paymentIntent.status);

            // 3Dセキュア認証が成功した場合、再度購入APIを呼び出す
            // レスポンスから元のリクエストデータを抽出
            const originalRequestData = response.value.original_request || {};
            console.log("[DEBUG] Original request data:", {
              ...originalRequestData,
              // 機密情報を除外
              card_token: originalRequestData.card_token
                ? "***" + originalRequestData.card_token.slice(-4)
                : null,
            });

            // 元のリクエストにPaymentIntentIDを追加
            const completeRequestData = {
              ...originalRequestData,
              payment_intent_id: paymentIntent.id,
            };

            console.log(
              "[DEBUG] Submitting purchase with 3D Secure completed:",
              { ...completeRequestData, payment_intent_id: paymentIntent.id },
            );

            try {
              // 同じエンドポイントに再度リクエスト
              console.log("[DEBUG] Calling onSubmit with completeRequestData");
              const completeResponse = await response.value.onSubmit(
                completeRequestData,
              );
              console.log(
                "[DEBUG] Final response after 3D Secure:",
                completeResponse,
              );

              setLoading(false); // 成功時にもローディング状態を解除
              console.log(
                "[DEBUG] Payment processing completed successfully, calling onSuccess",
              );
              onSuccess &&
                onSuccess(
                  completeResponse.value.data,
                  completeResponse.value.response,
                );
              return { success: true };
            } catch (submitError) {
              console.error(
                "[DEBUG] Error submitting final request:",
                submitError,
              );
              console.error(
                "[DEBUG] Error details:",
                JSON.stringify(
                  submitError,
                  Object.getOwnPropertyNames(submitError),
                ),
              );
              // 最終リクエスト送信エラーも国際化
              setError(getPureMessage("forms.attr.purchase.payment_failed"));
              setLoading(false);
              return null;
            }
          } else {
            console.log("[DEBUG] Payment unsuccessful:", paymentIntent.status);
            // 支払い失敗も国際化メッセージを使用
            setError(getPureMessage("forms.attr.purchase.payment_failed"));
            setLoading(false);
            return null;
          }
        } catch (confirmError) {
          console.error(
            "[DEBUG] Error during confirmCardPayment:",
            confirmError,
          );
          console.error(
            "[DEBUG] Error details:",
            JSON.stringify(
              confirmError,
              Object.getOwnPropertyNames(confirmError),
            ),
          );
          // 3Dセキュア認証エラーも国際化
          setError(getPureMessage("forms.attr.purchase.3d_secure_failed"));
          setLoading(false);
          return null;
        }
      } else {
        console.log("[DEBUG] No 3D Secure needed, proceeding with payment");
        console.log("[DEBUG] Final payment data:", paymentData);
        setLoading(false);
        console.log("[DEBUG] Calling onSuccess with paymentData");
        onSuccess && onSuccess(paymentData, response.value.response);
        return { success: true };
      }
    } catch (error) {
      console.error("[DEBUG] Error in handleServerResponse:", error);
      console.error("[DEBUG] Error type:", typeof error);
      console.error(
        "[DEBUG] Error details:",
        JSON.stringify(error, Object.getOwnPropertyNames(error)),
      );
      handleError(error);
      setLoading(false);
      return { success: false, error };
    }
  };

  /**
   * エラー処理
   */
  const handleError = (error) => {
    console.log("[DEBUG] Handling error:", error);

    // エラーメッセージの詳細な解析ではなく、より一般的なカテゴリでエラーを分類
    if (error.message) {
      // エラーメッセージにカードや支払い関連の単語が含まれているか大まかにチェック
      if (
        error.message.toLowerCase().includes("card") ||
        error.message.toLowerCase().includes("payment") ||
        error.message.toLowerCase().includes("credit") ||
        error.message.toLowerCase().includes("debit") ||
        error.message.toLowerCase().includes("charge")
      ) {
        // カード関連の全てのエラーを一つのメッセージにまとめる
        setError(getPureMessage("forms.attr.purchase.invalid_card"));
        return;
      }

      // 認証関連のエラー
      if (
        error.message.toLowerCase().includes("auth") ||
        error.message.toLowerCase().includes("secur") ||
        error.message.toLowerCase().includes("failed") ||
        error.message.toLowerCase().includes("verify")
      ) {
        // 認証関連の全てのエラーを一つのメッセージにまとめる
        setError(getPureMessage("forms.attr.purchase.3d_secure_failed"));
        return;
      }

      // その他の全てのエラーは一般的なメッセージにする
      setError(getPureMessage("forms.attr.purchase.payment_failed"));
    } else if (typeof error === "object") {
      // Check specifically for email validation errors
      if (error.email) {
        setError(error.email);
        return;
      }

      const message = Object.keys(error)
        .map((key) => error[key])
        .filter(Boolean)
        .join("\n");

      // 一般的なエラーメッセージも可能であれば国際化
      const translatedMessage =
        message || getPureMessage("forms.attr.purchase.invalid_request");
      setError(translatedMessage);
    } else {
      // デフォルトのエラーメッセージを国際化
      setError(getPureMessage("forms.attr.purchase.invalid_request"));
    }
  };

  return {
    loading,
    error,
    processPayment,
    setError,
  };
};

export default useStripePayment;
