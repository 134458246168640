const messages = {
  input: {
    sample: "Show sample",
  },
  samples: {
    artist: {
      biography:
        '1929　Born in Matsumoto city, Nagano Prefecture<br/>1957　Move to New York<br/>1973　Feel down and returned to Japan<br/>2004　Large-scale solo exhibition touring Japan<br/>2005　"Yayoi Kusama full print collection (1979-2004)" is published from Abe publication<br/>2009　In October, to be elected as a cultural contributor<br/>2017　"Yayoi Kusama Museum of Art" opens<br/>2017　Honorary citizen',
      history:
        '【Prize】<br/>1996　Received Best Gallery Award for 2 consecutive years by International Fine Arts Critics League<br/>2000　Received the 50th Arts Election Prize Minister\'s Prize and Foreign Minister\'s Award<br/>2001　Received the Asahi Award<br/>2002　Winning the Konjiri Reward<br/>2003　Participated in the Lyon Biennale The same year, received the French Arts Cultural Orchestra Officier, Nagano Prefecture Governor Award<br/>2006 The Order of the Rising Sun, Gold Rays with Rosette<br/>　Lifetime Achievement Awards<br/>　High PriestTakamatsu Memorial World Cultural Award<br/><br/>2014　Won the Ango award<br/>2016　Received the Order of Culture. Fourth person at a female painter<br/><br/>【Solo Exhibitions】<br/>1992　"Yayoi Kusama Exhibition Space Bouncing Space", Sogetsu Art Museum / Niigata Art Museum<br/>1999　"Yayoi Kusama New York / Tokyo", Tokyo Metropolitan Museum of Contemporary Art<br/>2004　"Kusa matrix", Mori Art Museum<br/>2007　Valencia Biennale<br/>2007　Victoria Milo Gallery<br/>2007　Liverpool Biennale<br/>2009　Gagoshian Gallery<br/>2011-2012　Touring Exhibition "YAYOI KUSAMA"<br/>　2011/5/11 - 9/12, Madrid, Queen Sofia Art Center<br/>　2011/10/10 - 2012/1/9, Paris, Pompidou Center<br/>　2012/2/9 - 6/5 London, Tate · Modern<br/>　2012/7/12 - 9/30 Whitney Museum of Art in New York<br/>2011 "Yusuke Kusama Exhibition Kusama\'s Body Festival in 60\'s", Watarium Museum of Art<br/>"Yayoi Kusama Eternal Eternity Eternity" (only a part is mentioned)<br/>　2012/1/7 - 4/8 National Museum of Art<br/>　2012/7/14 - 11/4 Matsumoto City Art Museum<br/>　2013/11/2 - 2014/1/13 Kochi Prefectural Museum of Art<br/>2017 "Yayoi Kusama Exhibition My Eternal Soul", National Art Center, Tokyo<br/>2018 "Yayoi Kusama ALL ABOUT MY LOVE All of my love", Matsumoto City Art Museum',
    },
  },
  auth: {
    signin: "Sign In",
    signup: "Sign Up",
    signout: "Sign Out",
  },
  attr: {
    ogp_url: "OGP URL",
    priority: "Priority(Descending)",
    required: "required",
    nickname: "Nickname",
    name: "Name",
    email: "Email",
    email_again: "Email again",
    contact_email_message:
      "If you would like to add an address to receive emails from the Art Scenes support team, please register it below.",
    contact_email: "Contact email",
    contact_email_again: "Contact email again",
    password: "Password",
    password_again: "Confirm password",
    new_password: "New password",
    new_password_again: "New password again",
    tel: "TEL",
    hp: "Homepage URL",
    user_lang: "User Language",
    publish_status: "Publish Status",
    expired_month: "Expired month",
    expired_year: "Expired year",
    expired: "Expired",
    card_number: "Card Number",
    cvc: "CVC",
    post_code: "ZIP code",
    address: "Address",
    place: "Place Keyword",
    gallery_message: "Message to colletor",
    section_id: "Section",
    title: "Title",
    posted_at: "Post Date",
    editor_name: "Editor Name",
    summary: "Summary",
    start_at: "Start from",
    vip_start_at: "VIP Start from",
    end_at: "End at",
    schedule: "Schedule",
    height: "Height",
    width: "Width",
    depth: "Depth",
    size_unit: "Size Unit",
    creation_year: "Created Year",
    category_id: "Classification",
    classification: "Classification Detail",
    medium: "Medium",
    color: "Color",
    price: "Price",
    price_unit: "Price Unit",
    sales_status: "Sales Status",
    birth_place: "Place of Birth",
    activity_area: "Activity Area",
    birth_year: "Year of Birth",
    death_year: "Year of Death",
    primary_lang: "Primary Language",
    country: "Country",
    map: "Map",
    related: "Related Content",
    no_select: "No Selection",
    opted_out: "Stop receiving emails from Art Scenes",
    fair_only_status: "Sub Status",
    status: "Status",
    question: "Message",
    free_word: "Free wrod",
    not_listed: "Not listed",
    only_started: "Only started",
    v_pass: "VIP Pass",
    slug: "slug",
    domestic_shipping_status: {
      status: "Domestic Shipping Status",
      not_free: "NOT FREE",
      free: "FREE",
    },

    exhibition_status: {
      all: "All",
      exhibited: "Exhibited",
    },
    gallery: {
      name: "Name",
      description: "About this gallery",
    },
    show: {
      title: "Show Title",
      description: "About this show",
    },
    bank: {
      number: "Bank Number",
      name: "Bank Name",
      branch: "Bank Branch",
      holder: "Bank Holder Name",
      is_qualified_invoice_issuer: "Qualified Invoice Issuer",
      qualified_invoice_issuer_id: "Invoice Registration Number",
      is_qualified_invoice_issuer_yes: 'Yes',
      is_qualified_invoice_issuer_no: 'No',
      is_qualified_invoice_issuer_default: 'Please select',
      qualified_invoice_issuer_id_error: 'Please enter the registration number (T followed by 13 digits).',
    },
    location: {
      name: "Location name",
      open_year: "Open Year",
    },
    information: {
      basic: "Basic Information",
      description: "Description",
      related: "Related",
    },
    artwork: {
      basic: "Basic Info",
      sale: "Sale Info",
      title: "Title",
      description: "About this artwork",
      history: "Award and History",
    },
    artist: {
      name: "Artist Name",
      history: "History of this artist",
      biography: "Biography",
      represents: "This artist represents your gallery.",
    },
    section: {
      label: "Section Name",
    },
    fair: {
      basic: "Basic Info",
      reception: "Reception Info",
      committee_title: "Executive Committee",
      partner: "Partner Info",
      title: "Fair Title",
      description: "About this fair",
      schedule: "Fair Schedule",
      ticket: "Ticket Information",
      ticket_url: "Ticket Site URL",
      reception_schedule: "Reception Schedule",
      reception_address: "Reception Address",
      reception_fee: "Reception Fee",
      organizer: "Organizer",
      chief_committee: "Chief Committee",
      committee: "committee",
      supporter: "Supporter",
      sponsorship: "Sponsorship",
      cooperator: "Cooperator",
    },
    search: {
      price: "PRICE",
      medium: "MEDIUM",
      width: "WIDTH",
      height: "HEIGHT",
      period: "PERIOD",
      place: "GALLERY LOCATION",
      color: "COLOR",
      sales_status: "SALES STATUS",
    },
    inquiry: {
      name: "Your Name",
      submit: "CONTACT GALLERY",
      message_placeholder:
        "Hi, I'm interested in purchasing this work. Could you please provide more information about the piece ?",
    },
    purchase: {
      invalid_card: "Invalid credit card",
      invalid_request: "Wow! This credit card cannot be used.",
      user_info: "User Information",
      credit_info: "Credit Information",
      name: "Your Name",
      message_placeholder:
        "Hi, I want to purchase this work. Could you please tel me about shipping?",
      credit_card: "Credit Card",
      submit: "Send Request",
      alipay: "Alipay",
      use_alipay: "Use Alipay",
      address: "Address to send",
      country: "Country to send",
      "3d_secure_failed":
        "Authentication failed. Please try with a different card or contact your card issuer.",
    },
    artwork_filter: {
      for_sale: "ONLY FOR SALE",
      size: "SIZE",
      price: "PRICE",
      medium: "MEDIUM",
      sort: "SORT BY",
      by_section: "By Sections",
      by_fair: "By Fairs",
      by_gallery: "By Galleries",
      by_artwork: "By Artworks",
      by_artist: "By Artists",
      section: "SECTION",
      choose: "CHOOSE",
    },
    timing_filter: {
      upcoming: "Upcoming",
      past: "Past",
      current: "Current",
    },
    section_filter: {
      section: "SECTION",
    },
    show_filter: {
      place: "LOCATION",
      all: "ALL",
    },
    contact: {
      name: "Name",
      description: "Detail",
      description_placeholder: "Please write down detail informaton.",
    },
    only: {
      label: "SETTING",
      all: "ALL",
      gallery: "GALLERY ONLY",
      artwork: "ARTWORK ONLY",
      article: "ARTICLE ONLY",
      artist: "ARTIST ONLY",
      show: "SHOW ONLY",
      fair: "FAIR ONLY ",
    },
    card: {
      inuse: "Card in use",
      new: "New card",
    },
  },
  validaton: {
    required: "Required",
    email: "Incorrect email",
    num: "Only number",
    password: "Password is more than 6 words",
    date: "Format to YYYY-MM-DD",
    minNumber: "Too small number",
    maxNumber: "Too large number",
    decimal: "Use half-size",
    tel: "Format to number only",
    zip: "Format to 000-0000",
    url: "Incorrect url",
    zero: "Use only 0",
    isConfirmed: "Not same value",
    isConfirmedEmail: "Not same email",
    isConfirmedPassword: "Not same password",
  },
  supply: {
    sales_status:
      "Sales Status should be selected form followings<br/><br/>・Editing<br/> Users cannot find this in your gallery's page.<br/><br/>・Not for sale<br/>Users can find this in your gallery's page but cannot contact or purchase this.<br/><br/>・Contact us<br/>Users can contact but cannot purchase.<br/><br/>・For sale<br/>Users can contact and purchase this.<br/><br/>・Sold<br/>Users cannot contact and purchase this but find this as your sales results.",
  },
  submit: "Submit",
  search: "Search",
  add: "Add",
  edit: "Edit",
  delete: "Delete",
  more: "MORE",
  reject: "Reject",
  confirm: "Confirm",
  loading: "Loading ...",
  sort_by: "SORT BY",
  back: "Back",
  sample: "【Sample】",
  placeholder: {
    name: "John Smith",
    email: "sample@sample.com",
    tel: "819012345678",
    country: "Japan",
    address: "#304 Villa Sakuragaoka, 29-35 Sakuragaokacho, Shibuya-ku, Tokyo",
  },
};

export default messages;
