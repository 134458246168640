import "babel-polyfill";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import App from "./screens/App";
import registerServiceWorker from "./registerServiceWorker";
import { Location } from "./utils/location";
import ReactGA from "react-ga";
import store from "./stores.js";
import "./assets/css/style.css";
import TagManager from "react-gtm-module";
import Upload from "./wrapper/uikit/upload";
import { configureSentry } from "../src/config/sentry";

configureSentry();

declare var UIkit;

if (process.env.REACT_APP_MODE == "production") {
  ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_TAG, {
    debug: false,
  });

  const TagManagerArgs = {
    gtmId: process.env.REACT_APP_GOOGLE_TAG_MANAGER,
  };
  TagManager.initialize(TagManagerArgs);
}
UIkit.component("upload", Upload);

// Load Stripe outside of the component tree
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

ReactDOM.render(
  <Provider store={store}>
    <Elements stripe={stripePromise}>
      <App lang={Location.getLang()} />
    </Elements>
  </Provider>,
  document.getElementById("root"),
);
registerServiceWorker();
