import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { bindActionCreators } from "redux";
import { Field, reduxForm } from "redux-form";
import moment from "moment";
import { mergeData } from "../../../actions/collector/auth";
import cards from "../../../assets/images/vendor/cards.svg";
import Textarea from "../../inputs/Textarea";
import TextInput from "../../inputs/TextInput";
import { Button } from "../../util/Clickable";
import { getPureMessage, IntlText } from "../../util/Text";
import * as validate from "../../util/Validator";
import useStripePayment from "../../../hooks/useStripePayment";

/**
 * ベース購入フォームコンポーネント
 * 既存カード/新規カードの選択と3Dセキュア対応の支払い処理を実装
 */
const BasePurchaseFormContent = (props) => {
  const {
    handleSubmit,
    invalid,
    initialValues,
    onSubmit,
    onSuccess,
    actions,
    error: formError,
    showNameField = true,
    showEmailField = true,
    readOnlyName = false,
    allowSavedCard = true,
    formName = "purchase-form",
  } = props;

  const [mode, setMode] = useState("card");
  const [newCard, setNewCard] = useState(
    !initialValues || !initialValues.expired_at || !allowSavedCard,
  );

  const stripe = useStripe();
  const elements = useElements();

  // 共通のStripe支払い処理フックを使用
  const { loading, error, processPayment, setError } = useStripePayment();

  const changeMode = (newMode) => {
    setMode(newMode);
  };

  const toggleNewCard = (isNew) => {
    setNewCard(isNew);
  };

  const handleFormSubmit = async (values) => {
    try {
      const result = await processPayment({
        stripe,
        elements,
        values,
        isNewCard: newCard,
        onSubmit,
        onSuccess,
        mergeData: actions.mergeData,
      });

      return result;
    } catch (e) {
      // このブロックは processPayment 内で例外をキャッチするため実行されないはずですが、
      // 万が一の場合のためのセーフティネットとして残しておきます
      console.error("Uncaught payment processing error:", e);
      // 統一されたエラーメッセージを使用
      setError(getPureMessage("forms.attr.purchase.payment_failed"));
      return null;
    }
  };

  return (
    <form
      onSubmit={handleSubmit(handleFormSubmit)}
      action={props.action}
      method="post"
    >
      <h4 className="uk-margin-small">
        <IntlText id="forms.attr.purchase.user_info" />
      </h4>
      <div className="uk-margin-small">
        {showNameField && (
          <div className="uk-margin-ssmall">
            {readOnlyName ? (
              <>
                <label>
                  <IntlText id="forms.attr.name" />
                </label>
                <div>
                  <span>{initialValues && initialValues.name}</span>
                </div>
              </>
            ) : (
              <Field
                name="name"
                type="text"
                component={TextInput}
                labelId="forms.attr.purchase.name"
                required
                validate={[validate.required]}
                placeholder={getPureMessage("forms.placeholder.name")}
              />
            )}
          </div>
        )}

        {showEmailField && (
          <div className="uk-margin-ssmall">
            <Field
              name="email"
              type="email"
              component={TextInput}
              labelId="forms.attr.email"
              required
              validate={[validate.required, validate.email]}
              placeholder={getPureMessage("forms.placeholder.email")}
            />
          </div>
        )}

        <div className="uk-margin-ssmall">
          <Field
            name="tel"
            type="tel"
            component={TextInput}
            labelId="forms.attr.tel"
            validate={[validate.required, validate.tel]}
            required
            placeholder={getPureMessage("forms.placeholder.tel")}
          />
        </div>
        <div className="uk-margin-ssmall">
          <Field
            name="country"
            type="text"
            component={TextInput}
            validate={[validate.required]}
            required
            labelId="forms.attr.purchase.country"
            placeholder={getPureMessage("forms.placeholder.country")}
          />
        </div>
        <div className="uk-margin-ssmall">
          <Field
            name="address"
            component={Textarea}
            className="uk-form-textarea-xsmall"
            validate={[validate.required]}
            required
            labelId="forms.attr.purchase.address"
            placeholder={getPureMessage("forms.placeholder.address")}
          />
        </div>
        <div className="uk-margin-ssmall">
          <Field
            className="uk-form-textarea-small"
            name="collector_message"
            component={Textarea}
            labelId="forms.attr.question"
            placeholder=" "
          />
        </div>
      </div>

      <h4 className="uk-margin-small">
        <IntlText id="forms.attr.purchase.credit_info" />
      </h4>
      <ul
        className="uk-subnav uk-subnav-pill uk-child-width-expand uk-margin-small"
        {...{ "uk-switcher": "" }}
      >
        <li>
          <a
            className="uk-text-center"
            href="#"
            onClick={() => changeMode("card")}
          >
            <IntlText id="forms.attr.purchase.credit_card" />
          </a>
        </li>
        {/*
        <li>
          <a className="uk-text-center" href="#" onClick={() => changeMode("alipay") }>
            <small><IntlText id="forms.attr.purchase.alipay" /></small>
          </a>
        </li>
        */}
      </ul>

      <ul className="uk-switcher uk-margin-small">
        <li>
          {/* 未ログインユーザー (allowSavedCard = false) の場合はタブを表示せず、直接クレジットカード入力フォームを表示 */}
          {!allowSavedCard ? (
            <div>
              <div className="uk-margin-ssmall">
                <img className="uk-width-1-1" src={cards} alt="cards" />
              </div>
              <div className="uk-margin-ssmall">
                <label className="uk-form-label uk-text-nowrap">
                  <IntlText id="forms.attr.card_number" />{" "}
                  <small className="uk-text-danger">
                    {" "}
                    ※<IntlText id="forms.attr.required" />
                  </small>
                </label>
                <div className="uk-form-controls">
                  <CardNumberElement className="uk-input uk-input-element" />
                </div>
              </div>
              <div
                className="uk-margin-ssmall uk-child-width-1-2@s uk-grid-small"
                data-uk-grid
              >
                <div>
                  <label className="uk-form-label uk-text-nowrap">
                    <IntlText id="forms.attr.expired" />
                    <small className="uk-text-danger">
                      {" "}
                      ※<IntlText id="forms.attr.required" />
                    </small>
                  </label>
                  <div className="uk-form-controls">
                    <CardExpiryElement className="uk-input uk-input-element" />
                  </div>
                </div>
                <div>
                  <label className="uk-form-label uk-text-nowrap">
                    <IntlText id="forms.attr.cvc" />{" "}
                    <small className="uk-text-danger">
                      {" "}
                      ※<IntlText id="forms.attr.required" />
                    </small>
                  </label>
                  <div className="uk-form-controls">
                    <CardCvcElement className="uk-input uk-input-element" />
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <>
              {/* 既存カード表示セクション (ログインユーザーのみ) */}
              {allowSavedCard && initialValues && initialValues.expired_at && (
                <div
                  className="uk-margin-ssmall uk-switcher-card"
                  active={"" + !newCard}
                  onClick={() => toggleNewCard(false)}
                >
                  <div>
                    <IntlText id="forms.attr.card.inuse" />
                  </div>
                  <div className="uk-text-muted">
                    {initialValues.card_suffix && (
                      <span className="uk-margin-ssmall-right">
                        **** **** **** {initialValues.card_suffix}
                      </span>
                    )}
                    {initialValues.expired_at && (
                      <span>
                        <span className="uk-margin-ssmall-right">
                          <IntlText id="forms.attr.expired" />
                        </span>
                        <span>
                          {moment(initialValues.expired_at).format("MM/YY")}
                        </span>
                      </span>
                    )}
                  </div>
                </div>
              )}
              <div
                className="uk-margin-ssmall uk-switcher-card"
                active={"" + newCard}
                onClick={() => toggleNewCard(true)}
              >
                <div>
                  <IntlText id="forms.attr.card.new" />
                </div>
              </div>
              <hr />
              {/* 新カード情報入力フォーム */}
              {(!initialValues || !initialValues.expired_at || newCard) && (
                <div>
                  <div className="uk-margin-ssmall">
                    <img className="uk-width-1-1" src={cards} alt="cards" />
                  </div>
                  <div className="uk-margin-ssmall">
                    <label className="uk-form-label uk-text-nowrap">
                      <IntlText id="forms.attr.card_number" />{" "}
                      <small className="uk-text-danger">
                        {" "}
                        ※<IntlText id="forms.attr.required" />
                      </small>
                    </label>
                    <div className="uk-form-controls">
                      <CardNumberElement className="uk-input uk-input-element" />
                    </div>
                  </div>
                  <div
                    className="uk-margin-ssmall uk-child-width-1-2@s uk-grid-small"
                    data-uk-grid
                  >
                    <div>
                      <label className="uk-form-label uk-text-nowrap">
                        <IntlText id="forms.attr.expired" />
                        <small className="uk-text-danger">
                          {" "}
                          ※<IntlText id="forms.attr.required" />
                        </small>
                      </label>
                      <div className="uk-form-controls">
                        <CardExpiryElement className="uk-input uk-input-element" />
                      </div>
                    </div>
                    <div>
                      <label className="uk-form-label uk-text-nowrap">
                        <IntlText id="forms.attr.cvc" />{" "}
                        <small className="uk-text-danger">
                          {" "}
                          ※<IntlText id="forms.attr.required" />
                        </small>
                      </label>
                      <div className="uk-form-controls">
                        <CardCvcElement className="uk-input uk-input-element" />
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
        </li>
      </ul>

      {error && (
        <div className="uk-margin-small uk-text-danger uk-text-center@s">
          <span>{error}</span>
        </div>
      )}

      {formError && (
        <div className="uk-margin-small uk-text-danger uk-text-center@s">
          <span>{formError}</span>
        </div>
      )}

      <div className="uk-margin-small uk-text-center">
        <Button
          className="uk-button uk-button-default"
          type="submit"
          disabled={invalid || (newCard && !stripe)}
          ga={{ category: formName, action: "submit" }}
          loading={loading ? 1 : 0}
        >
          <IntlText id="forms.attr.purchase.submit" />
        </Button>
      </div>

      {props.children}
    </form>
  );
};

// Redux Formでラップしたコンポーネントを作成するファクトリー関数
export const createPurchaseForm = (formName, enableReinitialize = true) => {
  const WrappedForm = reduxForm({
    form: formName,
    enableReinitialize,
  })(BasePurchaseFormContent);

  const mapStateToProps = (state) => {
    return {};
  };

  const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(
      {
        mergeData: (data) => {
          return dispatch(mergeData(data));
        },
      },
      dispatch,
    ),
  });

  const ConnectedForm = withRouter(
    connect(mapStateToProps, mapDispatchToProps)(WrappedForm),
  );

  // ラップ関数を返して、propsのパススルーをデバッグ
  return (props) => <ConnectedForm {...props} />;
};

export default BasePurchaseFormContent;
