const messages = {
  input: {
    sample: "記載例を見る",
  },
  samples: {
    artist: {
      biography:
        "1929　長野県松本市生まれ<br/>1957　ニューヨークへ渡る<br/>1973　体調を崩し日本へ帰国<br/>2004　大規模個展が日本を巡回<br/>2005　「草間彌生全版画集（1979-2004)」を阿部出版から刊行される<br/>2009　10月、文化功労者に選出される<br/>2017　「草間彌生美術館」が開館<br/>2017　 名誉都民",
      history:
        "【受賞歴】<br/>1996　国際美術評論家連盟から2年連続でベストギャラリー賞を受賞<br/>2000　第50回芸術選奨文部大臣賞、外務大臣表彰を受ける<br/>2001　朝日賞を受賞<br/>2002　紺綬褒章を受賞<br/>2003　リヨン・ビエンナーレに参加同年、フランス芸術文化勲章オフィシエ、長野県知事表彰を受ける<br/>2006　旭日小綬章<br/>　ライフタイムアチーブメント賞<br/>　高松宮殿下記念世界文化賞<br/>2014　安吾賞を受賞する<br/>2016　文化勲章を受章する女性画家では四人目<br/><br/>【個展】<br/>1992　『草間彌生展　はじける宇宙』、草月美術館／新潟市美術館<br/>1999　『草間彌生　ニューヨーク/東京』、東京都現代美術館<br/>2004　『クサマトリックス』、森美術館<br/>2007　バレンシアビエンナーレ<br/>2007　ビクトリアミロギャラリー<br/>2007　リバプールビエンナーレ<br/>2009　ガゴシアンギャラリー<br/>2011〜2012　巡回展『YAYOI KUSAMA』<br/>　2011/5/11 - 9/12　マドリッド、ソフィア王妃芸術センター<br/>　2011/10/10 - 2012/1/9　パリ、ポンピドゥー・センター<br/>　2012/2/9 - 6/5　ロンドン、テート・モダン<br/>　2012/7/12 - 9/30　ニューヨーク、ホイットニー美術館<br/>2011　『草間彌生展 Kusama's Body Festival in 60's』、ワタリウム美術館<br/>『草間彌生 永遠の永遠の永遠』（一部のみ記す）<br/>　2012/1/7 - 4/8　国立国際美術館<br/>　2012/7/14 - 11/4　松本市美術館<br/>　2013/11/2 - 2014/1/13　高知県立美術館<br/>2017　『草間彌生展 わが永遠の魂』、国立新美術館<br/>2018　『草間彌生　ALL ABOUT MY LOVE 私の愛のすべて』、松本市美術館",
    },
  },
  auth: {
    signin: "ログイン",
    signup: "新規登録",
    signout: "ログアウト",
  },
  attr: {
    ogp_url: "OGP URL",
    priority: "おすすめ表示順(降順)",
    required: "必須",
    nickname: "ニックネーム",
    name: "名前",
    email: "メールアドレス",
    email_again: "メールアドレスの確認",
    contact_email_message:
      "サポートチームからのメールを受信するアドレスを追加したい場合は、以下よりご登録ください。",
    contact_email: "連絡用メールアドレス",
    contact_email_again: "連絡用メールアドレスの確認",
    password: "パスワード",
    password_again: "パスワード（確認用）",
    new_password: "新しいパスワード",
    new_password_again: "新しいパスワードの確認",
    tel: "TEL",
    hp: "URL",
    primary_lang: "使用する言語",
    publish_status: "公開ステータス",
    expired_month: "有効期限(月)",
    expired_year: "有効期限(年)",
    expired: "有効期限",
    card_number: "クレジットカード番号",
    cvc: "セキュリティコード",
    post_code: "郵便番号",
    address: "住所",
    place: "都市",
    gallery_message: "コレクターへのメッセージ",
    section_id: "セクション",
    title: "タイトル",
    posted_at: "投稿日",
    editor_name: "編集者名",
    summary: "概要",
    start_at: "開始日",
    vip_start_at: "VIP開始日時",
    end_at: "終了日",
    schedule: "スケジュール",
    height: "高さ",
    width: "幅",
    depth: "奥行き",
    size_unit: "サイズの単位",
    creation_year: "作成年",
    category_id: "分類",
    classification: "分類詳細",
    medium: "メディウム",
    color: "色合い",
    price: "価格",
    price_unit: "価格単位",
    sales_status: "販売ステータス",
    birth_place: "生まれた地域",
    activity_area: "活動地域",
    birth_year: "生まれた年",
    death_year: "死亡した年",
    country: "国名",
    map: "Map",
    related: "関連コンテンツ",
    no_select: "選択なし",
    opted_out: "Art Scenesからのお得なメールの受信を停止する",
    fair_only_status: "サブステータス",
    status: "ステータス",
    question: "お問い合わせ内容",
    free_word: "フリーワード",
    not_listed: "一覧に非表示",
    only_started: "開催日時から作品公開",
    v_pass: "VIPパス",
    slug: "スラグ",
    domestic_shipping_status: {
      status: "国内送料",
      not_free: "有料（着払い）",
      free: "無料",
    },

    exhibition_status: {
      all: "全て",
      exhibited: "出展中",
    },
    gallery: {
      name: "ギャラリー名",
      description: "このギャラリーについて",
    },
    show: {
      title: "展示タイトル",
      description: "この展示について",
    },
    bank: {
      number: "口座番号",
      name: "銀行名",
      branch: "支店名",
      holder: "口座名義",
      is_qualified_invoice_issuer: "適格請求書発行事業者",
      qualified_invoice_issuer_id: "適格請求書発行事業者登録番号（インボイス番号）",
      is_qualified_invoice_issuer_yes: 'はい',
      is_qualified_invoice_issuer_no: 'いいえ',
      is_qualified_invoice_issuer_default: '選択してください',
      qualified_invoice_issuer_id_error: '登録番号（T+13桁の数字）を入力してください。',

    },
    location: {
      name: "支店名",
      open_year: "オープンした年",
    },
    information: {
      basic: "基本情報",
      description: "詳細",
      related: "関連情報",
    },
    artwork: {
      basic: "基本情報",
      sale: "販売情報",
      title: "タイトル",
      description: "この作品について",
      history: "受賞歴・展示歴など",
    },
    artist: {
      name: "アーティスト名",
      history: "受賞歴・展示歴",
      biography: "バイオグラフィ",
      represents: "ギャラリーを代表するアーティストです。",
    },
    section: {
      label: "セクション名",
    },
    fair: {
      basic: "基本情報",
      reception: "レセプション情報",
      committee_title: "企画者情報",
      partner: "パートナー情報",
      title: "フェア名",
      description: "このフェアについて",
      schedule: "開催スケジュール",
      ticket: "チケット情報",
      ticket_url: "チケットサイトへのリンク",
      reception_schedule: "レセプションスケジュール",
      reception_address: "レセプション会場の住所",
      reception_fee: "レセプション参加費",
      organizer: "主催",
      chief_committee: "実行委員長",
      committee: "委員会",
      supporter: "後援",
      sponsorship: "協賛",
      cooperator: "協力",
    },
    search: {
      price: "価格",
      medium: "メディウム",
      width: "幅",
      height: "高さ",
      period: "作成年",
      place: "地域",
      color: "色",
      sales_status: "販売ステータス",
    },
    inquiry: {
      name: "ご氏名",
      submit: "問い合わせる",
      message_placeholder:
        "この作品の購入を検討しています。遠方に滞在しているので配送料等に関して教えてください。",
    },
    purchase: {
      invalid_card: "このクレジットカードの情報が間違っています。",
      invalid_request: "このクレジットカードは利用できません。",
      user_info: "申し込み者情報",
      credit_info: "お支払い情報",
      name: "ご氏名",
      message_placeholder:
        "この作品の購入を希望します。遠方に滞在しているのですが、配送時期などについて教えてください。",
      credit_card: "クレジットカード",
      submit: "購入リクエストを送る",
      alipay: "Alipay",
      use_alipay: "Alipayで購入する",
      address: "配送先住所",
      country: "配送先国名",
      "3d_secure_failed":
        "認証に失敗しました。別のカードを選択するか、カード発行会社にお問い合わせください。",
    },
    artwork_filter: {
      for_sale: "販売中のみ",
      size: "サイズ",
      price: "価格",
      medium: "メディウム",
      sort: "並び順",
      by_section: "By Sections",
      by_fair: "By Fairs",
      by_gallery: "By Galleries",
      by_artwork: "By Artworks",
      by_artist: "By Artists",
      section: "展示セクション",
      choose: "選択する",
    },
    timing_filter: {
      upcoming: "開催予定",
      past: "開催済み",
      current: "開催中",
    },
    section_filter: {
      section: "展示セクション",
    },
    show_filter: {
      place: "地域",
      all: "全て",
    },
    contact: {
      name: "ご氏名",
      description: "お問い合わせ内容",
      description_placeholder: "問い合わせの内容について詳細をご記載ください。",
    },
    only: {
      label: "表示設定",
      all: "全て",
      gallery: "ギャラリーのみ",
      artwork: "作品のみ",
      article: "記事のみ",
      artist: "アーティストのみ",
      show: "展示のみ",
      fair: "フェアのみ",
    },
    card: {
      inuse: "利用中のカード",
      new: "新しいカード",
    },
  },
  validaton: {
    required: "必須項目です",
    email: "メールアドレスの形式が間違っています",
    num: "番号のみ入力できます",
    password: "パスワードは6文字以上入力してください",
    date: "YYYY-MM-DDの形式で入力してください",
    minNumber: "数値が小さすぎます",
    maxNumber: "数値が大きすぎます",
    decimal: "半角のみを利用してください",
    tel: "番号のみ(ハイフンなし)で入力してください",
    zip: "000-0000の形式で入力してください",
    url: "URLの形式が間違っています",
    zero: "0のみ入力できます",
    isConfirmed: "入力値が一致していません",
    isConfirmedEmail: "メールアドレスと一致していません",
    isConfirmedPassword: "パスワードと一致していません",
  },
  supply: {
    sales_status:
      "販売ステータスは以下から該当するものを選んでください。<br/><br/>・非公開<br/> 自身のギャラリーページ上には表示されません。<br/><br/>・非売品<br/>自身のギャラリーページに表示されますが、ユーザーは購入・問い合わせができません<br/><br/>・要問い合わせ<br/>ユーザーは問い合わせはできますが、購入はできません。<br/><br/>・販売中<br/>ユーザーは購入や問い合わせができます。<br/><br/>・売約済・販売済<br/>問い合わせや購入ができませんが、販売実績としてユーザーに表示されます",
  },
  submit: "送信",
  search: "検索",
  add: "追加",
  edit: "編集",
  delete: "削除",
  more: "MORE",
  reject: "拒否",
  confirm: "承認する",
  loading: "ロード中 ...",
  sort_by: "並び順",
  back: "戻る",
  sample: "【記載例】",
  placeholder: {
    name: "轟 太郎",
    email: "sample@sample.com",
    tel: "09012345678",
    country: "日本",
    address: "東京都渋谷区東１丁目２５−２　丸橋ビル４F",
  },
};

export default messages;
