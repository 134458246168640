import ReactGA from "react-ga";
import ReactPixel from "react-facebook-pixel";
import TwitterConvTrkr from "react-twitter-conversion-tracker-plus";

declare var twttr;
const FB_CNV_ID = "318190305617234";
const TWITTER_CNV_ID = "o04nm";

export default class Logger {
  static init(user) {
    if (process.env.REACT_APP_MODE != "production") {
      console.log(user);
      return;
    }

    ReactPixel.init(
      FB_CNV_ID,
      {
        em: user ? user.email : null,
      },
      { autoConfig: true, debug: false },
    );

    TwitterConvTrkr.init(TWITTER_CNV_ID);
  }

  static set(user) {
    if (process.env.REACT_APP_MODE != "production") {
      console.log(user);
      return;
    }
    if (user == null) {
      return;
    }
    ReactGA.set(user);
  }

  static pageview() {
    if (process.env.REACT_APP_MODE != "production") {
      console.log(window.location.pathname + window.location.search);
      return;
    }
    ReactGA.pageview(window.location.pathname + window.location.search);
    ReactPixel.pageView();
    TwitterConvTrkr.pageView();
  }

  static event(event) {
    if (process.env.REACT_APP_MODE != "production") {
      console.log(event);
      return;
    }
    if (event == null) {
      return;
    }
    ReactGA.event(event);
  }

  static purchase(price, unit, artworkId) {
    if (unit == "jpy" && price != null) {
      price /= 108;
    }
    if (process.env.REACT_APP_MODE != "production") {
      console.log(price);
      return;
    }

    if (typeof twttr !== "undefined" && twttr.conversion) {
      twttr.conversion.trackPid("o04ny", {
        tw_sale_amount: price,
        tw_order_quantity: 0,
      });
    }

    ReactPixel.track("purchase", {
      price: price,
      artworkId: artworkId,
    });

    ReactGA.event({
      category: "conversion",
      action: "purchase",
      label: "artwork_" + artworkId,
      value: Math.floor(price),
    });
  }

  static inquiry(price, unit, artworkId) {
    if (unit == "jpy") {
      price /= 108;
    }
    if (process.env.REACT_APP_MODE != "production") {
      console.log(price);
      return;
    }

    if (typeof twttr !== "undefined" && twttr.conversion) {
      twttr.conversion.trackPid("o04nz", {
        tw_sale_amount: price,
        tw_order_quantity: 0,
      });
    }

    ReactPixel.track("inquiry", {
      price: price,
      artworkId: artworkId,
    });
    ReactGA.event({
      category: "conversion",
      action: "inquiry",
      label: "artwork_" + artworkId,
      value: Math.floor(price),
    });
  }
}
