import React from "react";
import { createPurchaseForm } from "../commons/BasePurchaseForm";

/**
 * コレクター向け購入フォーム
 * 共通フォームコンポーネントをベースにカスタマイズ
 */
const CollectorPurchaseForm = createPurchaseForm(
  "collector-purchase-form",
  true,
);

// コレクター向けにカスタマイズしたPurchaseFormのラッパーコンポーネント
const PurchaseForm = (props) => {
  return (
    <CollectorPurchaseForm
      {...props}
      showNameField={true}
      readOnlyName={true}
      showEmailField={false}
      allowSavedCard={true}
    />
  );
};

export default PurchaseForm;
